@import "~@ng-select/ng-select/themes/default.theme.css";

*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Fix mobile Safari increase font-size on landscape mode */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

button:disabled {
  cursor: not-allowed !important;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
  user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
  box-sizing: border-box;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* remove default dot (•) sign */
::marker {
  content: initial;
}

/* fix the feature of 'hidden' attribute.
     display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
     - fix for the content editable attribute will work properly.
     - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  line-break: after-white-space;
  -webkit-user-select: auto;
  user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}


p {
  margin: 0;

}

a {
  text-decoration: none;
  color: var(--color-main-text);
  cursor: pointer;
}

a:hover {
  color: var(--color-main-text);
  text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

html {
  height: 100%;
  min-height: 100%;
}

body {
  font-family: 'Outfit', sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 18px;
  color: var(--color-main-text);
}

/* Color Variables */
:root {
  --color-green: #74E291;
  --color-white: #fff;
  --color-main-text: #383B42;
  --color-text-gray: #9F9F9F;
  --color-orange: #FF9A15;
  --color-blue: #48C2FF;
  --color-purple: #9647EA;
}

input::placeholder {
  color: #e5e5e5;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.form-control:focus {
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  border: 2px solid #e9ecef !important;
  cursor: not-allowed;
}

.text-gray {
  color: var(--color-text-gray);
}

.text-green {
  color: var(--color-green);
}

.text-main-color {
  color: var(--color-main-text) !important;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.small-btn {
  display: inline-block;
  background-color: #F4F5F7;
  color: var(--color-white);
  min-width: 85px;
  padding: 8px 20px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  height: fit-content;
}

.bg-green {
  background-color: var(--color-green);
}

.bg-orange {
  background-color: var(--color-orange);
}

.bg-purple {
  background-color: var(--color-purple);
}

.bg-blue {
  background-color: var(--color-blue);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item {
  margin: 0 8px;
}

.pagination li a {
  border: none;
  color: var(--color-main-text);
  font-size: 16px;
  background-color: transparent;
}


.page-item.active .page-link {
  background-color: var(--color-green);
  border-radius: 50%;
}

.pagination li.disabled {
  display: none;
}

.page-link:hover {
  color: var(--color-green);
  background-color: transparent;
  border: none;
}

.page-link:focus {
  box-shadow: none;
}

.pagination li.pagination-next a,
.pagination li.pagination-prev a {
  background-color: var(--color-green);
  border-radius: 50%;
  color: white;
  padding: 5px 12px 11px 12px;
  font-size: 30px;
  line-height: 20px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}


.arrow-down.rotate {
  transform: rotate(180deg)
}

.dropdown {
  position: absolute;
  background: white;
  border-radius: 10px;
  border: 1px solid #EFEFEF;
  padding: 15px;
  z-index: 1;
  display: none !important;
  max-height: 400px;
  overflow: auto;
}

.dropdown.active {
  display: block !important;
}

.dropdown li {
  padding: 5px 0;
}

.gm-style .gm-style-iw-tc {
  display: none !important;
}

button.gm-ui-hover-effect {
  top: 15px !important;
  right: 20px !important;
}

.gm-ui-hover-effect>span {
  background-color: var(--color-green);
  width: 18px !important;
  height: 18px !important;
}

.nav.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  background-color: var(--color-green);
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 8px;
  color: var(--color-white);
  padding: 10px 20px;
}

.nav-tabs .nav-link.active {
  color: var(--color-main-text);
}

.card {
  border-radius: 10px;
  border-color: #E9E9E9;
  color: #ababab;
}

.card .big-text {
  font-size: 19px;
  line-height: 1;
}

.progress {
  height: 7px;
  background-color: #F7F7F7;
  border-radius: 10px;
}

.progress-bar {
  border-radius: 10px;
}

.orange-progressbar .progress-bar {
  background-color: var(--color-orange);
}

.green-progressbar .progress-bar {
  background-color: var(--color-green);
}

.lh-1 {
  line-height: 1;
}

.bs-datepicker-body table td.week span {
  color: var(--color-green) !important;
}

.bs-datepicker {
  border-radius: 10px;
  border: 1px solid #DADADA;
  margin: 4px 0;
}

.theme-green .bs-datepicker-head {
  font-size: 14px;
  background-color: var(--color-green);
}

.bs-datepicker-body table td span.selected {
  background-color: var(--color-green) !important;
}

.bs-datepicker-body table td {
  color: var(--color-main-text);
}

.bs-datepicker-body table th {
  color: #A8A8A8;
}

.btn:focus {
  box-shadow: none;
}

.mat-mdc-snack-bar-container {
  text-align: center;
  color: white;
  font-weight: 700;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
}

.mat-mdc-snack-bar-container.error-snackbar {
  --mdc-snackbar-container-color: #FF5D5B;
}

.mat-mdc-snack-bar-container.success-snackbar {
  --mdc-snackbar-container-color: var(--color-green);
}

.mat-mdc-snack-bar-container.info-snackbar {
  --mdc-snackbar-container-color: var(--color-purple);
}

.gm-style-iw-chr {
  position: absolute;
  top: -7px;
  right: -10px;
}

.ng-select-container {
  border: none !important;
  width: 250px !important;
}

.show-password-icon {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 55px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-green);
}


@media only screen and (max-width: 480px) {
  .nav .nav-item {
    width: 100%;
  }

  .nav .nav-link {
    border-radius: 10px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 418px) {
  .pagination .page-item {
    margin: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-expansion-panel {
  background-color: white !important;
  color: #32333A !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  color: #32333A !important;
}
